import { useEffect } from "react";
import CardNoImageItem from "../components/CardNoImageItem";
import NavbarItem from "../components/NavbarItem";

function NoPage() {
    let props = {'title': 'Page not found!', 'desc': 'There is nothing here', 'btn_text': 'Back to home', 'url': '/', 'valignmiddle': true}

    useEffect(() => {
        document.documentElement.classList.add("height", "100%")
        document.body.classList.add("height", "100%")
    });

    return (
        <div>
            <NavbarItem/>
            <CardNoImageItem {...props}/>
        </div>
    )
}

export default NoPage;