function WhatsAppIconItem() {
    const sendMsg = (e) => {
        /* let cmp = e.currentTarget.parentNode.previousSibling.childNodes[0]
        cmp.classList.remove("input-error")
        if(cmp.value === undefined || (cmp.value.length>9 && cmp.value.length<15)) window.open("https://api.whatsapp.com/send?phone="+(cmp.value === undefined ? "6289617895220&text=Hai%20John,%0aRegarding%20your%20website%20:%20https//www.johnnatan.my.id%0a%0aMessage%20here%20:" : cmp.value), "wa_msg")
        else if(cmp.value !== undefined) cmp.classList.add("input-error") */
        let cmp = e.currentTarget.parentNode.previousSibling.childNodes[0].childNodes[0].childNodes[1]
        window.open("https://api.whatsapp.com/send?phone="+(cmp.value === 0 ? "6289617895220&text=Hai%20John,%0aRegarding%20your%20website%20:%20https//www.johnnatan.my.id%0a%0aMessage%20here%20:" : "62"+cmp.value), "wa_msg")
    }
    return (
        <div>
            <div className="fixed bottom-20 right-5 pt-2 pl-2 border border-white dark:border-white rounded-full">
                <label htmlFor="my-modal">
                    <svg className="fill-current w-10 h-10 dark:text-white">
                    <path d="M19.6,19.47A5,5,0,1,1,21,16v1.5a1.5,1.5,0,0,0,3,0V16a8,8,0,1,0-4.94,7.4A1,1,0,0,1,20,25.18l-.14.06A10,10,0,1,1,26,16v1.5a3.5,3.5,0,0,1-6.4,2ZM16,19a3,3,0,1,0-3-3A3,3,0,0,0,16,19Z"></path>
                    </svg>
                </label>
                <input type="checkbox" id="my-modal" className="modal-toggle" />
                <div className="modal modal-middle">
                    <div className="modal-box relative">
                        <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                        <h3 className="text-lg font-bold">Send a whatsapp message to :</h3>
                        <div className="py-4">
                            <div className="form-control">
                                <label className="input-group">
                                    <span>+62</span>
                                    <input type="text" placeholder="a phone number" className="input input-bordered w-full max-w" onKeyPress={(e) => { if(!/[0-9]/.test(e.key)) {e.preventDefault();} }}/>
                                </label>
                            </div>
                            {/* <input placeholder="A phone no." className="input input-bordered w-full max-w" onKeyPress={(e) => { if(!/[0-9]/.test(e.key)) {e.preventDefault();} }}/> */}
                        </div>
                        <div className="float-right">
                            <button className="btn btn-primary" onClick={(e) => sendMsg(e)}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-5 right-5 pt-2 pl-2 border border-white dark:border-white rounded-full" onClick={(e) => sendMsg(e)} style={{"cursor":"pointer"}}>
                <label>
                    <svg className="fill-current w-10 h-10 dark:text-white">
                    <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"></path>
                    </svg>
                </label>
            </div>
        </div>
  );
}

export default WhatsAppIconItem;