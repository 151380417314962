function ListItem(props) {
    const calc_yrmt = (months) => {
      let nyear = Math.floor(months/12);
      let nmonth = months % 12;
      return (nyear>0 ? nyear + ' year' + (nyear>1 ? 's' : '') + ' ' : '') + nmonth + ' month' + (nmonth>1 ? 's' : '');
    }
    const month_diff = (d1, d2) => {
      var months;
      months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
      months -= new Date(d1).getMonth();
      months += new Date(d2).getMonth();
      // return months <= 0 ? 0 : months;
      return calc_yrmt(months);
    }

    return (
        <div className="flex flex-col container mt-10 mx-auto w-full items-center justify-center dark:bg-gray-800 rounded-lg shadow">
  <ul className="flex flex-col divide-y w-full">
    { Object.entries(props).map(([k, v]) => {return <li key={k} className="flex flex-row">
      <div className="select-none cursor-pointer hover:bg-base-200 flex flex-1 items-center p-4">
        {/* <div className="flex flex-col w-10 h-10 justify-center items-center mr-4">
          <a href="#" className="block relative">
            <img alt="profil" src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=150&q=80" className="mx-auto object-cover rounded-full h-10 w-10" />
          </a>
        </div> */}
        <div className="flex-1 pl-1">
          <div className="font-medium">{ (v.url===undefined ? v.title : <a href={v.url} target="open_url">{v.title}</a>) }</div>
          <div className="text-gray-600 text-white text-sm">{ v.desc }</div>
        </div>
        <div className="flex flex-row justify-center">
          <div className="text-gray-600 text-white text-xs">{ (v.start===undefined ? v.label : new Date(v.start).toLocaleString('en-EN', {'dateStyle':'medium'}).replace(/ \d\,/, '') + ' - ' + new Date(v.end).toLocaleString('en-EN', {'dateStyle':'medium'}).replace(/ \d\,/, '') ) } <div className="text-right">{ (v.start===undefined ? '' : month_diff(v.start, v.end)) }</div></div>
          {/* <button className="w-10 text-right flex justify-end">
            <svg width="20" fill="currentColor" height="20" className="hover:text-gray-800 dark:hover:text-white dark:text-gray-200 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
              <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
            </svg>
          </button> */}
        </div>
      </div>
    </li>} ) }
  </ul>
</div>
    )
}

export default ListItem;