function SkillLevelItem(props) {
    return (
        <div className="flex w-full lg:w-auto m-2">
            <div className="grid h-20 flex-grow card bg-base-300 dark:bg-slate-600 rounded-box place-items-center text-[12px] md:text-lg text-left pt-1 pl-5 pr-5 w-1/2 z-0">{ props.name }</div>
            <div className="divider divider-horizontal"></div>
            <div className="grid h-20 flex-grow card bg-base-300  dark:bg-slate-600 rounded-box place-items-center p-2 w-1/2 z-0">
            {/* <div className="rating rating-xs md:rating-md rating-half gap-1">
                <input type="radio" name="rating-3" className="mask mask-star mask-half-1 bg-lime-400" {... (props.value===0.5 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-2 bg-lime-400" {... (props.value===1 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-1 bg-lime-400" {... (props.value===1.5 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-2 bg-lime-400" {... (props.value===2 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-1 bg-lime-400" {... (props.value===2.5 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-2 bg-lime-400" {... (props.value===3 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-1 bg-lime-400" {... (props.value===3.5 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-2 bg-lime-400" {... (props.value===4 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-1 bg-lime-400" {... (props.value===4.5 ? {checked:true} : '') }/>
                <input type="radio" name="rating-3" className="mask mask-star mask-half-2 bg-lime-400" {... (props.value===5 ? {checked:true} : '') }/>
            </div> */}
                {/* <div className="rating rating-lg rating-half">
                    <input type="radio" name="rating-10" className="rating-hidden" />
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-1"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-2"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-1"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-2"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-1"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-2"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-1"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-2"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-1"/>
                    <input type="radio" name="rating-10" className="bg-green-500 mask mask-star-2 mask-half-2"/>
                </div> */}
                <div className="radial-progress bg-primary text-primary-content border-4 border-primary" style={{"--value": `${props.value * 20}`, "--size":"3rem"}}>{props.value * 20}%</div>
            </div>
        </div>
    )
}

export default SkillLevelItem;