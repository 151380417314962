function CardNoImageItem(props) {
    let cardItem = <div className={'card'+(props.valignmiddle===true ? ' m-auto' : '')+' w-96 bg-base-100 dark:bg-gray-700 shadow-xl dark:shadow-white/20'}>
                <div className="card-body">
                <h2 className="card-title dark:text-white">{ props.title }</h2>
                <p className="dark:text-white">{ props.desc }</p>
                <div className="card-actions justify-end">
                    {(props.url === "undefined" ? <button className="btn btn-primary">{ props.btn_text }</button> : <a href={props.url}><button className="btn btn-primary">{ props.btn_text }</button></a>)}
                </div>
                </div>
            </div>

    return (
        (props.valignmiddle===true ? <div className="flex h-screen"> { cardItem } </div> : cardItem)
    )
}

export default CardNoImageItem;