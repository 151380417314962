// import { Dropdown, Indicator, Button, Badge, Card, Navbar } from "react-daisyui";
import { useState, useEffect } from 'react';
import DarkLightModeItem from './DarkLightModeItem';

function NavbarItem() {
    // source : https://daisyui.com/components/
    const [mode, setMode] = useState();
    useEffect(() => {
      /* if(mode===0) {
        document.documentElement.setAttribute("class", "dark");
        document.body.className = "bg-gray-700";
      } else {
        document.documentElement.removeAttribute("class");
        document.body.className = "";
      } */
      if(window.localStorage.getItem("mode") !== null) {
        document.documentElement.setAttribute("class", window.localStorage.getItem("mode"));
        document.body.className = "bg-gray-700";
      } else {
        document.documentElement.removeAttribute("class");
        document.body.className = "";
      }
    });
    const scrollTo = (e) => {
      const url = new URL(window.location.href)
      console.log(e.target.childNodes[0].data)
      if(url.pathname !== '/') window.location.href = '/'+(e.target.childNodes[0].data===undefined ? '' : '#'+e.target.childNodes[0].data.toLowerCase());
      if(e.target.childNodes[0].data!==undefined) {
        let el = document.getElementById(e.target.childNodes[0].data.toLowerCase())
        document.activeElement.blur()
        window.scrollTo({'top':(["skills","portfolio","experiences"].includes(el!==undefined && e.target.childNodes[0].data!==undefined && e.target.childNodes[0].data.toLowerCase())!==false ? el.offsetTop : 50) + 50, 'behavior':'smooth'})
      } else {
        window.scrollTo({'top': 100, 'behavior': 'smooth'});
      }
    }

    return (
      <div className="sticky top-0 navbar bg-base-100 dark:bg-gray-700 z-50">
  <div className="flex-1" onClick={(e) => scrollTo(e)}>
    <a className="btn btn-ghost normal-case text-xl dark:text-white">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
      </svg>
    </a>
  </div>
  <div className="flex">
    <DarkLightModeItem toChild={mode} sendToParent={setMode}/>
    {/* <div className="dropdown dropdown-end">
      <label tabIndex={0} className="btn btn-ghost btn-circle">
        <div className="indicator">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" /></svg>
          <span className="badge badge-sm indicator-item">8</span>
        </div>
      </label>
      <div tabIndex={0} className="mt-3 card card-compact dropdown-content w-52 bg-base-100 dark:bg-gray-700 shadow text-black dark:text-white">
        <div className="card-body">
          <span className="font-bold text-lg">8 Items</span>
          <span className="text-info">Subtotal: $999</span>
          <div className="card-actions">
            <button className="btn btn-primary btn-block">View cart</button>
          </div>
        </div>
      </div>
    </div> */}
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
        <div className="w-10 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 dark:text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </div>
      </label>
      <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow dark:shadow-white bg-base-100 dark:bg-gray-700 rounded-box w-52">
        <li className="md:p-2" onClick={(e) => scrollTo(e)}>
          <a className="justify-between">
            Skills
            <span className="badge float-right">Updates</span>
          </a>
        </li>
        <li className="md:p-2" onClick={(e) => scrollTo(e)}><a>Portfolio</a></li>
        <li className="md:p-2" onClick={(e) => scrollTo(e)}><a>Experiences</a></li>
      </ul>
    </div>
  </div>
</div>
    ); 
}

export default NavbarItem;