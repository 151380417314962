import ListItem from "./ListItem";
import SkillLevelItem from "./SkillLevelItem";
import './ParallaxItem.css'

function ParallaxItem() {
    let skills = [{"name":"PHP (Native, CodeIgniter, Laravel)","value":4.75},
        {"name":"Mysql, PostgreSQL (DML, Views, Stored Procedure)","value":4.5},
        {"name":"Basic CSS, Bootstrap CSS, Tailwind CSS","value":4.5},
        {"name":"Javascript (Native, JQuery, JQuery UI)","value":4.75},
        {"name":"Java Spring Boot","value":2},
        {"name":"Angular","value":2.5},
        {"name":"React JS","value":1.5},
        {"name":"Git (Github, Gitlab, Bitbucket)","value":3.5},
        {"name":"Windows, Linux & Mac OS","value":4},
        {"name":"Jenkins","value":1}];
    let portfolio = [{'title':'Laku Emas Indonesia','url':'https://www.lakuemas.com'},
        {'title':'Haidilao PIK','url':'https://haidilaopik.com'},
        {'title':'Stevato','url':'https://stevato.com'},
        {'title':'Cadenza Music Art','url':'https://cadenzamusicart.com'},
        {'title':'Masshive Media','url':'https://masshive-media.com'}];
    let experiences = [{"title":"PT. Generasi Teknologi Buana", "desc":"placement at Astrapay", "start": new Date(2022, 5), "end": Date.now()},
        {"title":"PT. Laku Emas Indonesia", "start": new Date(2018, 7), "end": new Date(2022, 4)},
        {"title":"PT. Asian Technology Solutions", "desc":"placement PT. Serasi Autoraya", "start": new Date(2017, 10), "end": new Date(2018, 6)},
        {"title":"PT. FAIMS", "start": new Date(2012, 5), "end": new Date(2017, 7)},
        {"title":"PT. Prima Teknologi", "start": new Date(2009, 6), "end": new Date(2012, 3)},
        {"title":"Toko Buku Online Bukukita.com", "start": new Date(2007, 4), "end": new Date(2009, 5)},
        {"title":"PT. Solis Logic", "start": new Date(2006, 1), "end": new Date(2007, 3)}]
    const scrollTo = (e) => {
        console.log(e.target.parentNode.tagName.toLowerCase())
        if(["header","section"].includes([e.target.parentNode.tagName.toLowerCase()])!==false) {console.log("in")
            let el = document.getElementById(e.target.childNodes[0].data.toLowerCase())
            document.activeElement.blur()
            window.scrollTo({'top':(["skills","portfolio","experiences"].includes(el!==undefined && e.target.childNodes[0].data!==undefined && e.target.childNodes[0].data.toLowerCase())!==false ? el.offsetTop : 50) + 50, 'behavior':'smooth'})
        } else {console.log("out")
            window.scrollTo({'top': 100, 'behavior': 'smooth'});
        }
    }
      
    return (
        <>
        <header className="flex items-center justify-center h-screen mb-12 bg-fixed bg-center bg-cover custom-img">
            <div className="btn marquee" onClick={(e) => scrollTo(e)}>
                <div>Welcome to my site!</div>
            </div>
        </header>
        <section id="skills" className="container flex items-center justify-center h-screen m-auto mb-12 bg-fixed bg-center bg-cover custom-img">
            <div className="btn animate-bounce" onClick={(e) => scrollTo(e)}>
                Skills
            </div>
        </section>
        <div className="w-full m-auto p-6 dark:text-white">
            <p className="mb-4 text-justify">
            Graduated on Bina Nusantara university at 2006 since then I've been a web developer. It's been a great journey to learn the developing technologies to upgrade my skills.
            </p>
            <div className="mb-4 text-justify">
                <ul className="list-disc lg:pl-8" style={{"listStyleType":"none"}}>
                    {Object.entries(skills).map(([k,v]) => {return <li key={k}><SkillLevelItem {...v}/></li>})}
                    {/* <li><SkillLevelItem/></li>
					<li>Mysql, PostgreSQL (DML, Views, Stored Procedure)</li>
					<li>Basic CSS, Bootstrap CSS, Tailwind CSS</li>
					<li>Javascript (Native, JQuery, JQuery UI)</li>
					<li>Java Spring Boot</li>
                    <li>Angular</li>
                    <li>React JS</li>
					<li>Git (Github, Gitlab, Bitbucket)</li>
					<li>Windows, Linux & Mac OS</li>
					<li>Jenkins</li> */}
                </ul>
            </div>
        </div>
        <section id="portfolio" className="container flex items-center justify-center h-screen m-auto mb-12 bg-fixed bg-center bg-cover custom-img">
            <div className="btn animate-pulse">
                Portfolio
            </div>
        </section>
        <div className="w-full m-auto p-6 dark:text-white">
            <ListItem {...portfolio}/>
            {/* <div className="mb-4 text-justify">
                <ul className="list-disc">
                    <li className="ml-8"><a href="https://www.lakuemas.com" target="open_url">Laku Emas Indonesia</a></li>
					<li className="ml-8"><a href="https://haidilaopik.com" target="open_url">Haidilao PIK</a></li>
					<li className="ml-8"><a href="https://stevato.com" target="open_url">Stevato</a></li>
					<li className="ml-8"><a href="https://cadenzamusicart.com" target="open_url">Cadenza Music Art</a></li>
					<li className="ml-8"><a href="https://masshive-media.com" target="open_url">Masshive Media</a></li>
                </ul>
            </div> */}
        </div>
        <section id="experiences" className="container flex items-center justify-center h-screen m-auto mb-12 bg-fixed bg-center bg-cover custom-img">
            <div className="btn animate-bounce">
                Experiences
            </div>
        </section>
        <div className="w-full m-auto p-6 dark:text-white pb-32">
            <ListItem {...experiences}/>
            {/* <div className="mb-4 text-justify mb-20">
                <ul className="list-disc">
					<li className="ml-8">PT. Generasi Teknologi Buana, placement at Astrapay (June 2022 - now)</li>
                    <li className="ml-8">PT. Laku Emas Indonesia (Aug 2018 - May 2022)</li>
					<li className="ml-8">PT. Asian Technology Solutions, placement PT. Serasi Autoraya (Nov 2017 - Jul 2018)</li>
					<li className="ml-8">PT. FAIMS (May 2012 - Aug 2017)</li>
					<li className="ml-8">PT. Prima Teknologi (Jul 2009 - Apr 2012)</li>
					<li className="ml-8">Toko Buku Online Bukukita.com (May 2007 - Jun 2009)</li>
					<li className="ml-8">PT. Solis Logic (Feb 2006 - Apr 2007)</li>
                </ul>
            </div> */}
        </div>
        </>
    )
}

export default ParallaxItem;